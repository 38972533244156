import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "popup-confirmation",
  class: "modal"
}
const _hoisted_2 = { class: "modal__content modal__content--lg" }
const _hoisted_3 = { class: "p-5 flex flex-col" }
const _hoisted_4 = { class: "text-xl" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.statePopupConfirmation.title), 1),
        _createElementVNode("div", {
          class: "text-gray-600 my-5 text-sm",
          innerHTML: _ctx.statePopupConfirmation.message
        }, null, 8, _hoisted_5),
        _createElementVNode("div", {
          class: _normalizeClass([
            'pt-5 mt-1 border-t border-gray-400',
            _ctx.statePopupConfirmation.buttonsPosition === 'l' && 'text-left',
            _ctx.statePopupConfirmation.buttonsPosition === 'left' && 'text-left',
            _ctx.statePopupConfirmation.buttonsPosition === 'c' && 'text-center',
            _ctx.statePopupConfirmation.buttonsPosition === 'center' &&
              'text-center',
            _ctx.statePopupConfirmation.buttonsPosition === 'r' && 'text-right',
            _ctx.statePopupConfirmation.buttonsPosition === 'right' && 'text-right',
          ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statePopupConfirmation.buttons, (btn, idx) => {
            return (_openBlock(), _createElementBlock("button", {
              key: 'popup-confirmation-button-' + idx,
              class: _normalizeClass([
              'button min-w-24',
              btn.type === 'outline' &&
                'border text-gray-700 dark:border-dark-5 dark:text-gray-300',
              btn.type === 'primary' &&
                'bg-theme-1 hover:bg-primary text-white',
              btn.type === 'danger' && 'bg-theme-6 text-white',
              'mx-2',
              idx === 0 && _ctx.statePopupConfirmation.buttons?.length && 'ml-0',
              idx + 1 === _ctx.statePopupConfirmation.buttons?.length && 'mr-0',
            ]),
              type: "button",
              onClick: btn.action
            }, _toDisplayString(btn.text), 11, _hoisted_6))
          }), 128))
        ], 2)
      ])
    ])
  ]))
}