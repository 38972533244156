
import {
  popupConfirmation,
  statePopupConfirmation,
} from "@/utils/popup/confirmation";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      statePopupConfirmation,
      popupConfirmation,
    };
  },
});
